.sound-wave {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sound-wave::before,
  .sound-wave::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    background: rgba(0, 150, 255, 0.5);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: soundWave 1.5s infinite ease-out;
  }
  
  .sound-wave::after {
    animation-delay: 0.5s;
  }
  
  @keyframes soundWave {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(2.5);
      opacity: 0;
    }
  }
  