.call-container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
   
   
}

.video-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 100%;
   
}

.local-video {
    position: absolute;
    right: 10px;
    bottom: 5px;
    object-fit: cover;
    aspect-ratio: 16 / 9;
    width: 150px;
    height: auto;
    
}
.remote-video {
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16 / 9;
}

.grid-video{
    width: 49%;
    height: auto;
    aspect-ratio: 16 / 9;
    margin: 5px;
    
}

.call-controls{
   
  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
   color: #fff;
   z-index: 9999;
   padding-right: 10px;

}

.call-container button{
  /* color: #fff; */
  background-color: #ffffff11;
  border-radius: 4px;
}
.call-container button:hover{
    background-color: #ffffff44;
}

.call-container button:disabled{
    color: #ffffffb5;
    background-color: #ffffff11;
    cursor: not-allowed;
}
.call-container button{
    margin: 6px;

}


.call-header{
    background-color: #0c1016;
    width: 100%;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 0;
    font-weight:300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}